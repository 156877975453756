import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Popover, Button, Space, Size, Select } from '@hse-design/react';
import { useState } from 'react';
import { Playground, Props } from 'docz';
import { ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "popover"
    }}>{`Popover`}</h1>
    <pre><code parentName="pre" {...{}}>{`import { Popover } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma="https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=6879%3A17725" storybook="/?path=/story/popover-popover--playground" vue="/#/popover" vueStorybook="/?path=/story/popover-popover--playground" mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Popover — это плавающее окно, прикрепленное к какому-либо элементу-триггеру.
Пользователь может взаимодействовать с содержимым Popover, в том числе выбирать текст или нажимать ссылки.`}</p>
    <p>{`Для того, чтобы показывать или скрывать Popover, рекомендуется использовать явное действие пользователя,
например нажатие на кнопку, а не просто наведение указателя на элемент (используйте компонент Tooltip для отображения информации при наведении).`}</p>
    <p>{`Используйте Popover, чтобы создавать всплывающие меню, либо сложные подсказки.`}</p>
    <p>{`Для позиционирования Popover используется библиотека `}<a parentName="p" {...{
        "href": "https://popper.js.org/"
      }}>{`Popper.js`}</a>{`.`}</p>
    <p>{`Popover привязывается к элементу, переданному в `}<inlineCode parentName="p">{`children`}</inlineCode>{`, либо к DOM-элементу, переданному в проп `}<inlineCode parentName="p">{`appendTo`}</inlineCode>{` (см. примеры ниже).`}</p>
    <p>{`Popover - контролируемый компонент. Для того, чтобы показывать или скрывать его, необходимо использовать внешнее состояние.`}</p>
    <Playground __position={1} __code={'() => {\n  const [active, setActive] = useState(false)\n  return (\n    <Popover\n      active={active}\n      arrow={true}\n      onClickOutside={() => setActive(false)}\n      content={\n        <div className=\"hse-Text_paragraph-small\">\n          A popover is a light roll made from an egg batter similar to that of\n          Yorkshire pudding, typically baked in muffin tins or dedicated\n          popover pans, which have straight-walled sides rather than angled\n        </div>\n      }\n    >\n      <div style={{ display: \'inline-block\' }}>\n        <Button style={{ width: \'150px\' }} onClick={() => setActive(!active)}>\n          {active ? \'Close\' : \'Open\'} Popover\n        </Button>\n      </div>\n    </Popover>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Popover,
      Button,
      Space,
      Size,
      Select,
      useState,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    {() => {
        const [active, setActive] = useState(false);
        return <Popover active={active} arrow={true} onClickOutside={() => setActive(false)} content={<div className="hse-Text_paragraph-small">
                        A popover is a light roll made from an egg batter similar
                        to that of Yorkshire pudding, typically baked in muffin tins
                        or dedicated popover pans, which have straight-walled sides
                        rather than angled
                    </div>} mdxType="Popover">
                <div style={{
            display: "inline-block"
          }}>
                    <Button style={{
              width: '150px'
            }} onClick={() => setActive(!active)} mdxType="Button">
                        {active ? 'Close' : 'Open'} Popover
                    </Button>
                </div>
            </Popover>;
      }}
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node="%3Fnode-id%3D8267%253A53085" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node="%3Fnode-id%3D8267%253A53091" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "использование-children"
    }}>{`Использование children`}</h3>
    <h4 {...{
      "id": "передача-элемента"
    }}>{`Передача элемента`}</h4>
    <p>{`Если передать элемент в `}<inlineCode parentName="p">{`children`}</inlineCode>{`, то Popover привяжется к нему.
В `}<inlineCode parentName="p">{`children`}</inlineCode>{` нельзя передать несколько элементов или использовать `}<inlineCode parentName="p">{`React.Fragment`}</inlineCode>{`.`}</p>
    <p>{`Убедитесь, что элемент, передаваемый в `}<inlineCode parentName="p">{`children`}</inlineCode>{` может принимать `}<inlineCode parentName="p">{`ref`}</inlineCode>{`.
Если элемент не принимает `}<inlineCode parentName="p">{`ref`}</inlineCode>{`, то можно обернуть его в промежуточный `}<inlineCode parentName="p">{`div`}</inlineCode>{`, стилизовав его необходимым образом.`}</p>
    <p>{`Большинство элементов ДС не принимают `}<inlineCode parentName="p">{`ref`}</inlineCode>{`, либо принимают рефы через `}<inlineCode parentName="p">{`innerRef`}</inlineCode>{`.
Поэтому их необходимо оборачивать, или использовать отдельный проп для рефа, как описано в следующем разделе.`}</p>
    <Playground __position={4} __code={'() => {\n  const [active, setActive] = useState(false)\n  return (\n    <Popover\n      active={active}\n      arrow={true}\n      content={\n        <div className=\"hse-Text_paragraph-small\">\n          A popover is a light roll made from an egg batter similar to that of\n          Yorkshire pudding, typically baked in muffin tins or dedicated\n          popover pans, which have straight-walled sides rather than angled\n        </div>\n      }\n    >\n      <div style={{ display: \'inline-block\' }}>\n        <Button style={{ width: \'150px\' }} onClick={() => setActive(!active)}>\n          {active ? \'Close\' : \'Open\'} Popover\n        </Button>\n      </div>\n    </Popover>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Popover,
      Button,
      Space,
      Size,
      Select,
      useState,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    {() => {
        const [active, setActive] = useState(false);
        return <Popover active={active} arrow={true} content={<div className="hse-Text_paragraph-small">
                        A popover is a light roll made from an egg batter similar
                        to that of Yorkshire pudding, typically baked in muffin tins
                        or dedicated popover pans, which have straight-walled sides
                        rather than angled
                    </div>} mdxType="Popover">
              <div style={{
            display: 'inline-block'
          }}>
                <Button style={{
              width: '150px'
            }} onClick={() => setActive(!active)} mdxType="Button">
                    {active ? 'Close' : 'Open'} Popover
                </Button>
              </div>
            </Popover>;
      }}
    </Playground>
    <h4 {...{
      "id": "отдельный-проп-для-рефа"
    }}>{`Отдельный проп для рефа`}</h4>
    <p>{`Если элемент принимает `}<inlineCode parentName="p">{`ref`}</inlineCode>{` через какой-то отдельный проп, например `}<inlineCode parentName="p">{`innerRef`}</inlineCode>{`,
то можно указать на это с помощью пропа `}<inlineCode parentName="p">{`refPropName`}</inlineCode>{`.`}</p>
    <p>{`Некоторые компоненты ДС принимают рефы через проп `}<inlineCode parentName="p">{`innerRef`}</inlineCode>{` (например Button).
Обращайтесь к документации конкретного компонента, чтобы определить, принимает ли он реф.`}</p>
    <Playground __position={5} __code={'() => {\n  const [active, setActive] = useState(false)\n  return (\n    <Popover\n      active={active}\n      arrow={true}\n      refPropName={\'innerRef\'}\n      content={\n        <div className=\"hse-Text_paragraph-small\">\n          A popover is a light roll made from an egg batter similar to that of\n          Yorkshire pudding, typically baked in muffin tins or dedicated\n          popover pans, which have straight-walled sides rather than angled\n        </div>\n      }\n    >\n      <Button style={{ width: \'150px\' }} onClick={() => setActive(!active)}>\n        {active ? \'Close\' : \'Open\'} Popover\n      </Button>\n    </Popover>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Popover,
      Button,
      Space,
      Size,
      Select,
      useState,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [active, setActive] = useState(false);
        return <Popover active={active} arrow={true} refPropName={"innerRef"} content={<div className="hse-Text_paragraph-small">
            A popover is a light roll made from an egg batter similar
            to that of Yorkshire pudding, typically baked in muffin tins
            or dedicated popover pans, which have straight-walled sides
            rather than angled
          </div>} mdxType="Popover">
        <Button style={{
            width: '150px'
          }} onClick={() => setActive(!active)} mdxType="Button">
          {active ? 'Close' : 'Open'} Popover
        </Button>
      </Popover>;
      }}
    </Playground>
    <h4 {...{
      "id": "рендер-функция"
    }}>{`Рендер-функция`}</h4>
    <p>{`В сложных случаях вы можете использовать рендер-функцию.
Она принимает единственным аргументом реф-колбек, который необходимо пробросить в нужное место.`}</p>
    <Playground __position={6} __code={'() => {\n  const [active, setActive] = useState(false)\n  return (\n    <Popover\n      active={active}\n      arrow={true}\n      refPropName={\'innerRef\'}\n      content={\n        <div className=\"hse-Text_paragraph-small\">\n          A popover is a light roll made from an egg batter similar to that of\n          Yorkshire pudding, typically baked in muffin tins or dedicated\n          popover pans, which have straight-walled sides rather than angled\n        </div>\n      }\n    >\n      {setRef => (\n        <Button\n          innerRef={setRef}\n          style={{ width: \'150px\' }}\n          onClick={() => setActive(!active)}\n        >\n          {active ? \'Close\' : \'Open\'} Popover\n        </Button>\n      )}\n    </Popover>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Popover,
      Button,
      Space,
      Size,
      Select,
      useState,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [active, setActive] = useState(false);
        return <Popover active={active} arrow={true} refPropName={"innerRef"} content={<div className="hse-Text_paragraph-small">
            A popover is a light roll made from an egg batter similar
            to that of Yorkshire pudding, typically baked in muffin tins
            or dedicated popover pans, which have straight-walled sides
            rather than angled
          </div>} mdxType="Popover">
        {setRef => <Button innerRef={setRef} style={{
            width: '150px'
          }} onClick={() => setActive(!active)} mdxType="Button">
            {active ? 'Close' : 'Open'} Popover
          </Button>}
      </Popover>;
      }}
    </Playground>
    <h3 {...{
      "id": "appendto"
    }}>{`appendTo`}</h3>
    <p>{`Popover можно привязать к произвольному DOM-элементу с помощью пропа `}<inlineCode parentName="p">{`appendTo`}</inlineCode>{`.`}</p>
    <Playground __position={7} __code={'() => {\n  const [active, setActive] = useState(false)\n  const [anchor, setAnchor] = useState()\n  return (\n    <>\n      <Button\n        innerRef={setAnchor}\n        style={{ width: \'150px\' }}\n        onClick={() => setActive(!active)}\n      >\n        {active ? \'Close\' : \'Open\'} Popover\n      </Button>\n      <Popover\n        appendTo={anchor}\n        active={active}\n        arrow={true}\n        refPropName={\'innerRef\'}\n        content={\n          <div className=\"hse-Text_paragraph-small\">\n            A popover is a light roll made from an egg batter similar to that\n            of Yorkshire pudding, typically baked in muffin tins or dedicated\n            popover pans, which have straight-walled sides rather than angled\n          </div>\n        }\n      />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Popover,
      Button,
      Space,
      Size,
      Select,
      useState,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [active, setActive] = useState(false);
        const [anchor, setAnchor] = useState();
        return <>
      <Button innerRef={setAnchor} style={{
            width: '150px'
          }} onClick={() => setActive(!active)} mdxType="Button">
        {active ? 'Close' : 'Open'} Popover
      </Button>
      <Popover appendTo={anchor} active={active} arrow={true} refPropName={"innerRef"} content={<div className="hse-Text_paragraph-small">
            A popover is a light roll made from an egg batter similar
            to that of Yorkshire pudding, typically baked in muffin tins
            or dedicated popover pans, which have straight-walled sides
            rather than angled
          </div>} mdxType="Popover" />
    </>;
      }}
    </Playground>
    <p>{`Так как Popover использует компонент Popper, то с помощью пропа `}<inlineCode parentName="p">{`appendTo`}</inlineCode>{` его можно также
привязать к `}<a parentName="p" {...{
        "href": "/src-components-popper/"
      }}>{`произвольным координатам`}</a>{`.`}</p>
    <h3 {...{
      "id": "позиционирование"
    }}>{`Позиционирование`}</h3>
    <p>{`Используйте свойство `}<inlineCode parentName="p">{`placement`}</inlineCode>{` для задания необходимого положения плавающего окна относительно элемента-триггера.`}</p>
    <p>{`Допустимые значения соответствуют параметру `}<inlineCode parentName="p">{`placement`}</inlineCode>{` из `}<a parentName="p" {...{
        "href": "https://popper.js.org/"
      }}>{`Popper.js`}</a>{`:
`}<inlineCode parentName="p">{`auto`}</inlineCode>{`, `}<inlineCode parentName="p">{`auto-end`}</inlineCode>{`, `}<inlineCode parentName="p">{`top-start`}</inlineCode>{`, `}<inlineCode parentName="p">{`top`}</inlineCode>{`, `}<inlineCode parentName="p">{`top-end`}</inlineCode>{`, `}<inlineCode parentName="p">{`right-start`}</inlineCode>{`, `}<inlineCode parentName="p">{`right`}</inlineCode>{`, `}<inlineCode parentName="p">{`right-end`}</inlineCode>{`, `}<inlineCode parentName="p">{`bottom-end`}</inlineCode>{`, `}<inlineCode parentName="p">{`bottom`}</inlineCode>{`, `}<inlineCode parentName="p">{`bottom-start`}</inlineCode>{`, `}<inlineCode parentName="p">{`left-end`}</inlineCode>{`, `}<inlineCode parentName="p">{`left`}</inlineCode>{`, `}<inlineCode parentName="p">{`left-start`}</inlineCode>{`.`}</p>
    <p>{`Если с нужной стороны не хватает места для расположения плавающего окна, то выбирается другая свободная сторона.
Для контроля этого поведения можно использовать проп `}<inlineCode parentName="p">{`popperOptions`}</inlineCode>{` для передачи дополнительных параметров
в Popper.js в соответствии с `}<a parentName="p" {...{
        "href": "https://popper.js.org/docs/v2/modifiers/flip/"
      }}>{`документацией`}</a>{`.`}</p>
    <Playground __position={8} __code={'() => {\n  const placements = [\n    { label: \'auto\', value: \'auto\' },\n    { label: \'auto-end\', value: \'auto-end\' },\n    { label: \'top-start\', value: \'top-start\' },\n    { label: \'top\', value: \'top\' },\n    { label: \'top-end\', value: \'top-end\' },\n    { label: \'right-start\', value: \'right-start\' },\n    { label: \'right\', value: \'right\' },\n    { label: \'right-end\', value: \'right-end\' },\n    { label: \'bottom-end\', value: \'bottom-end\' },\n    { label: \'bottom\', value: \'bottom\' },\n    { label: \'bottom-start\', value: \'bottom-start\' },\n    { label: \'left-end\', value: \'left-end\' },\n    { label: \'left\', value: \'left\' },\n    { label: \'left-start\', value: \'left-start\' },\n  ]\n  const [active, setActive] = useState(false)\n  const [value, setValue] = useState(\'auto\')\n  return (\n    <>\n      <Select\n        value={value}\n        onChange={setValue}\n        options={placements}\n        placeholder=\"Select the value\"\n      />\n      <Space size={Size.small} vertical />\n      <Popover\n        active={active}\n        arrow={true}\n        placement={value}\n        refPropName={\'innerRef\'}\n        onClickOutside={() => setActive(false)}\n        content={\n          <div className=\"hse-Text_paragraph-small\">\n            A popover is a light roll made from an egg batter similar to that\n            of Yorkshire pudding, typically baked in muffin tins or dedicated\n            popover pans, which have straight-walled sides rather than angled\n          </div>\n        }\n      >\n        <Button style={{ width: \'150px\' }} onClick={() => setActive(!active)}>\n          {active ? \'Close\' : \'Open\'} Popover\n        </Button>\n      </Popover>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Popover,
      Button,
      Space,
      Size,
      Select,
      useState,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    {() => {
        const placements = [{
          label: 'auto',
          value: 'auto'
        }, {
          label: 'auto-end',
          value: 'auto-end'
        }, {
          label: 'top-start',
          value: 'top-start'
        }, {
          label: 'top',
          value: 'top'
        }, {
          label: 'top-end',
          value: 'top-end'
        }, {
          label: 'right-start',
          value: 'right-start'
        }, {
          label: 'right',
          value: 'right'
        }, {
          label: 'right-end',
          value: 'right-end'
        }, {
          label: 'bottom-end',
          value: 'bottom-end'
        }, {
          label: 'bottom',
          value: 'bottom'
        }, {
          label: 'bottom-start',
          value: 'bottom-start'
        }, {
          label: 'left-end',
          value: 'left-end'
        }, {
          label: 'left',
          value: 'left'
        }, {
          label: 'left-start',
          value: 'left-start'
        }];
        const [active, setActive] = useState(false);
        const [value, setValue] = useState('auto');
        return <>
                <Select value={value} onChange={setValue} options={placements} placeholder="Select the value" mdxType="Select" />
                <Space size={Size.small} vertical mdxType="Space" />
                <Popover active={active} arrow={true} placement={value} refPropName={'innerRef'} onClickOutside={() => setActive(false)} content={<div className="hse-Text_paragraph-small">
                            A popover is a light roll made from an egg batter similar
                            to that of Yorkshire pudding, typically baked in muffin tins
                            or dedicated popover pans, which have straight-walled sides
                            rather than angled
                        </div>} mdxType="Popover">
                    <Button style={{
              width: '150px'
            }} onClick={() => setActive(!active)} mdxType="Button">
                        {active ? 'Close' : 'Open'} Popover
                    </Button>
                </Popover>
            </>;
      }}
    </Playground>
    <h3 {...{
      "id": "контент-в-края"
    }}>{`Контент в края`}</h3>
    <p>{`Для создания кастомизированных плавающих окон может потребоваться убрать встроенный отступ от рамки окна.
Для этого передайте в `}<inlineCode parentName="p">{`baseProps`}</inlineCode>{` проп `}<inlineCode parentName="p">{`noPadding`}</inlineCode>{` компонента `}<inlineCode parentName="p">{`PopoverBase`}</inlineCode>{`:`}</p>
    <Playground __position={9} __code={'() => {\n  const [active, setActive] = useState(false)\n  return (\n    <Popover\n      active={active}\n      arrow={true}\n      refPropName={\'innerRef\'}\n      onClickOutside={() => setActive(false)}\n      content={\n        <div className=\"hse-Text_paragraph-small\">\n          A popover is a light roll made from an egg batter similar to that of\n          Yorkshire pudding, typically baked in muffin tins or dedicated\n          popover pans, which have straight-walled sides rather than angled\n        </div>\n      }\n      baseProps={{\n        noPadding: true,\n      }}\n    >\n      <Button style={{ width: \'150px\' }} onClick={() => setActive(!active)}>\n        {active ? \'Close\' : \'Open\'} Popover\n      </Button>\n    </Popover>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Popover,
      Button,
      Space,
      Size,
      Select,
      useState,
      Playground,
      Props,
      ComponentLinks,
      FigmaEmbed,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [active, setActive] = useState(false);
        return <Popover active={active} arrow={true} refPropName={'innerRef'} onClickOutside={() => setActive(false)} content={<div className="hse-Text_paragraph-small">
            A popover is a light roll made from an egg batter similar
            to that of Yorkshire pudding, typically baked in muffin tins
            or dedicated popover pans, which have straight-walled sides
            rather than angled
          </div>} baseProps={{
          noPadding: true
        }} mdxType="Popover">
        <Button style={{
            width: '150px'
          }} onClick={() => setActive(!active)} mdxType="Button">
          {active ? 'Close' : 'Open'} Popover
        </Button>
      </Popover>;
      }}
    </Playground>
    <h2 {...{
      "id": "параметры"
    }}>{`Параметры`}</h2>
    <Props of={Popover} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      